import "./Thanks.css";

const Confirmation = () => {
  return (
    <div className="thanks">
      <div className="thanks__message">Thank you :D</div>
    </div>
  );
};

export default Confirmation;
